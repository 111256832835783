import { DocumentationCategory } from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
import { FormField, Multiselect } from '@amzn/awsui-components-react';
import { ReactElement, useState, useEffect } from 'react';
import { FieldProps } from '../FieldProps';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';

export const PromoteCategoriesField = (
  props: FieldProps<DocumentationCategory[], false> & {
    availableOptions: { value: string; label: string }[];
    onChange: (selectedCategories: DocumentationCategory[]) => void;
  }
): ReactElement => {
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const options = props.availableOptions.map((category) => ({
    value: category.value,
    label: category.label,
  }));

  useEffect(() => {
    const newSelectedOptions = selectedOptions.filter((selected: any) =>
      props.availableOptions.some((option) => option.value === selected.value)
    );
    setSelectedOptions(newSelectedOptions);
  }, [props.availableOptions]);

  const handleChange = (detail: any) => {
    setSelectedOptions(detail.selectedOptions);
    const selectedCategories = detail.selectedOptions.map(
      (option: OptionDefinition) => ({
        id: option.value,
        categoryName: option.label,
      })
    );
    props.onChange(selectedCategories);
  };

  return (
    <FormField
      label='Promoted category'
      description='This is for user to select categories under which this service entry (offering) need to always show up on the top regardless of the alphabetic order.'
    >
      <Multiselect
        disabled={props.disabled}
        options={props.availableOptions}
        selectedOptions={selectedOptions}
        onChange={({ detail }) => handleChange(detail)}
      />
    </FormField>
  );
};
