import { FormField, Input, Select } from '@amzn/awsui-components-react';
import { ReactElement, useEffect, useState } from 'react';
import { FieldProps } from '../FieldProps';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
const empty: OptionDefinition = {
  value: undefined,
  label: 'Select...',
};

export const HomepageTitleField = (
  props: FieldProps<string, true> & {
    longNameEntityId?: string;
  }
): ReactElement => {
  const [valid, setValid] = useState(true);

  useEffect(() => {
    const isValid = !props.required || props.disabled || !!props.value;
    setValid(isValid);
  }, [props.required, props.value, props.disabled]);

  useEffect(() => {
    if (props.onValidate) {
      props.onValidate(valid);
    }
  }, [valid]);

  return (
    <FormField label='HomePage Service Name'>
      <Input
        value={props.longNameEntityId || ''}
        readOnly
        disabled={true}
        placeholder='Value specified for Long name Entity ID'
      />
    </FormField>
  );
};
