import {
  DocumentationCategory,
  ListDocumentationCategoriesCommand,
  ListDocumentationCategoriesCommandOutput,
} from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
import { FormField, Multiselect } from '@amzn/awsui-components-react';
import { ReactElement } from 'react';
import { FieldProps } from '../FieldProps';
import { callApi } from '../../../client';
import { useState, useEffect } from 'react';
import { useAuthState } from '../../../authentication';

export const DocsCategoriesField = (
  props: FieldProps<DocumentationCategory[], false>
): ReactElement => {
  const [categories, setCategories] = useState<DocumentationCategory[]>([]);
  const { token } = useAuthState();
  useEffect(() => {
    if (!token) return;
    const getCategories = async () => {
      try {
        const fetchedCategories: ListDocumentationCategoriesCommandOutput =
          await callApi(new ListDocumentationCategoriesCommand({}), token);
        setCategories(fetchedCategories.categories);
      } catch (error) {
        console.error('Error fetching documentation categories:', error);
      }
    };

    getCategories();
  }, []);

  const options = categories.map((category) => ({
    value: category.id,
    label: category.categoryName,
  }));
  const areEqual = (
    arr1: DocumentationCategory[],
    arr2: DocumentationCategory[]
  ): boolean => {
    if (arr1.length !== arr2.length) return false;
    return arr1.every((value, index) => value.id === arr2[index].id);
  };
  return (
    <FormField label='Documentation categories'>
      {props.old && !areEqual(props.old, props.value) && (
        <Multiselect
          disabled={props.disabled}
          options={options}
          selectedOptions={options.filter((o) =>
            props.old?.some((oldValue) => oldValue.id === o.value)
          )}
        />
      )}
      <Multiselect
        disabled={props.disabled}
        onChange={(e) =>
          props.onChange?.(
            e.detail.selectedOptions
              .map((o) =>
                categories.find((category) => category.id === o.value)
              )
              .filter(
                (category): category is DocumentationCategory =>
                  category !== undefined
              )
          )
        }
        options={options}
        selectedOptions={options.filter((o) =>
          props.value.some((value) => value.id === o.value)
        )}
      />
    </FormField>
  );
};
