import { FormField, Input } from '@amzn/awsui-components-react';
import { ReactElement, useEffect, useState } from 'react';
import { FieldProps } from '../FieldProps';

export const HomepageLinkField = (
  props: FieldProps<string, true>
): ReactElement => {
  const [valid, setValid] = useState(false);
  useEffect(() => {
    const isValid: boolean =
      (!props.required && props.disabled) ||
      (Boolean(props.value) && /^\/.{0,254}$/.test(props.value));

    setValid(isValid);
  }, [props.required, props.value, props.disabled]);
  useEffect(() => props.onValidate?.(valid), [valid]);
  return (
    <FormField
      label='HomePage Link'
      errorText={
        !valid && props.value
          ? "Input must start with '/' and be between 1 and 255 characters."
          : undefined
      }
    >
      {props.old && props.old !== props.value && (
        <Input disabled={true} value={props.old} />
      )}
      <Input
        disabled={props.disabled}
        value={props.value}
        invalid={!valid}
        onChange={({ detail: { value } }) => {
          props.onChange?.(value);
        }}
        placeholder='/<path-slug>/'
      />
    </FormField>
  );
};
