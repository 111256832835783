import { Partition } from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
import { FormField, Multiselect } from '@amzn/awsui-components-react';
import { ReactElement, useEffect, useState } from 'react';
import { FieldProps } from '../FieldProps';

const options = Object.entries(Partition).map(
  ([value, label]): { value: string; label: string } => ({
    value,
    label,
  })
);
export const RegionsField = (
  props: FieldProps<Partition[], true>
): ReactElement => {
  const [valid, setValid] = useState<boolean>(false);

  useEffect(() => {
    const isValid = !props.required || props.disabled || props.value.length > 0;
    setValid(isValid);
  }, [props.required, props.value, props.disabled]);

  useEffect(() => {
    props.onValidate?.(valid);
  }, [valid]);

  return (
    <FormField
      label='Region Profiling'
      errorText={!valid ? 'Select one or more regions' : ''}
    >
      <Multiselect
        disabled={props.disabled}
        onChange={(e) =>
          props.onChange?.(
            e.detail.selectedOptions.map((o) => o.value as Partition)
          )
        }
        options={options}
        selectedOptions={options.filter((o) =>
          props.value.includes(o.value as Partition)
        )}
        invalid={!valid}
      />
    </FormField>
  );
};
