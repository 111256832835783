import {
  FormField,
  Input,
  Popover,
  TextContent,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import { ReactElement, useEffect, useState } from 'react';
import { FieldProps } from '../FieldProps';

export const ShortDescriptionField = (
  props: FieldProps<string, true>
): ReactElement => {
  const [valid, setValid] = useState(true);
  useEffect(() => {
    const isValid: boolean =
      !props.required ||
      props.disabled ||
      (Boolean(props.value) && /^.{1,255}$/.test(props.value));
    setValid(isValid);
  }, [props.value, props.required, props.disabled]);
  useEffect(() => props.onValidate?.(valid), [valid]);
  return (
    <FormField
      label='Short description'
      stretch
      description={
        <Popover
          triggerType='custom'
          position='right'
          size='small'
          content={
            <TextContent>
              <p>
                {' '}
                Run &lt;noloc&gt;Kubernetes&lt;/noloc&gt; on &lt;shared
                id=&quot;AWS&quot;/&gt; without operating your own
                &lt;noloc&gt;Kubernetes&lt;/noloc&gt; clusters
              </p>
            </TextContent>
          }
        >
          Click for Example usage
        </Popover>
      }
    >
      <Input
        disabled={props.disabled}
        value={props.value}
        invalid={!valid}
        onChange={({ detail: { value } }) => {
          props.onChange?.(value);
        }}
      />

      {typeof props.old === 'string' && props.old !== props.value && (
        <Input disabled={true} value={props.old} />
      )}
    </FormField>
  );
};
