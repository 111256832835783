import {
  Button,
  Header,
  Table,
  TableProps,
  TextFilter,
} from '@amzn/awsui-components-react/polaris';
import { fieldTitles } from './f2/fieldTitles';
import { ReactElement, useEffect, useState } from 'react';
import {
  Prefs,
  OfferingListCollectionPreferences,
} from './OfferingListCollectionPreferences';
import { offeringReviewPropList } from './RowPropTypes';

import { SortingColumn } from '@amzn/awsui-collection-hooks/dist/cjs/interfaces';
import { Pagination } from '@amzn/awsui-components-react';

import { useAuthState } from '../authentication';
import {
  GetCurrentUserCommand,
  GetCurrentUserOutput,
  ListOfferingReviewsCommand,
  OfferingReview,
} from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
import { callApi } from '../client';
import { makeOfferingOrDraftCell } from './makeOfferingOrDraftCell';
import { NoTokenError } from '../errors';

export const OfferingReviewList = (): ReactElement => {
  const DRAFTS_COLUMNS = 'offeringDraftsColumns';
  const DRAFTS_PAGE_SIZE = 'offeringDraftsPageSize';
  const { token } = useAuthState();
  const [sortingDescending, setSortingDescending] = useState(false);

  const [sortColumn, setSortColumn] = useState<SortingColumn<OfferingReview>>({
    sortingField: 'offeringName',
  });
  const [rows, setRows] = useState<OfferingReview[]>([]);
  const [filteredRows, setFilteredRows] = useState<OfferingReview[]>([]);
  const [queriedMocks, setQueriedMocks] = useState(false);
  const [user, setUser] = useState<GetCurrentUserOutput>();
  const [queriedUser, setQueriedUser] = useState(false);
  useEffect(() => {
    if (queriedUser || user || !token) return;
    setQueriedUser(true);
    callApi(new GetCurrentUserCommand({}), token)
      .then(setUser)
      .catch((err) => alert('GetCurrentUser call failed\n' + String(err)));
  }, [user, queriedUser]);
  const orderRows = () => {
    const field = (sortColumn.sortingField ??
      'offeringName') as keyof OfferingReview;
    return [...filteredRows].sort(
      sortingDescending
        ? (a, b) => String(b[field]).localeCompare(String(a[field]))
        : (a, b) => String(a[field]).localeCompare(String(b[field]))
    );
  };
  const [orderedRows, setOrderedRows] = useState(orderRows());
  const [filteringText, setFilteringText] = useState('');
  const getPage = (page: number, size: number) =>
    orderedRows.slice((page - 1) * size, page * size);
  const [items, setItems] = useState<OfferingReview[]>([]);
  const VISIBLE_COLUMNS: (keyof OfferingReview)[] = [
    // 'controls',
    'offeringName',
    // 'serviceName',
    // 'isPrefixRequired',
    'brandPrefix',
    // 'extraLongName',
    // 'shortName',
    // 'extraShortName',
    // 'doNotUseName',
    // 'generalNotes',
    // 'resourceExamples',
    'localizationGuidance',
    // 'localizationNotes',
    // 'docsCategory',
    'type',
    // 'deprecatedName',
    // "locale",
    'offeringReviewStatus',
  ];
  const [preferences, setPreferences] = useState<Prefs>({
    pageSize: 20,
    wrapLines: true,
    visibleContent: VISIBLE_COLUMNS as string[],
  });
  // const allHidden = services.reduce((o, serv) => ({...o, [serv.id]: false}), {});
  const [currentPage, setCurrentPage] = useState(1);

  // Set initial visible columns from localStorage if it exists
  useEffect(() => {
    if (localStorage.getItem(DRAFTS_COLUMNS)) {
      const savedVisibleCols = localStorage.getItem(DRAFTS_COLUMNS)?.split(',');
      if (savedVisibleCols)
        setPreferences({ ...preferences, visibleContent: savedVisibleCols });
    }
    if (localStorage.getItem(DRAFTS_PAGE_SIZE)) {
      const pageSize = localStorage.getItem(DRAFTS_PAGE_SIZE);
      if (pageSize)
        setPreferences({ ...preferences, pageSize: parseInt(pageSize) });
    }
  }, []);

  // Save column preferences to localStorage
  useEffect(() => {
    localStorage.setItem(
      DRAFTS_COLUMNS,
      preferences.visibleContent?.toString() ?? ''
    );
  }, [preferences.visibleContent]);

  // Save column preferences to localStorage
  useEffect(() => {
    localStorage.setItem(
      DRAFTS_PAGE_SIZE,
      preferences.pageSize?.toString() ?? ''
    );
  }, [preferences.pageSize]);
  useEffect(() => {
    if (queriedMocks) return;
    // if (!token) {
    //   console.error('No token to call API');
    //   return;
    // }
    if (!token) throw new NoTokenError();
    setQueriedMocks(true);
    callApi(
      new ListOfferingReviewsCommand({}),
      token
      // 'mockOfferingReviewsData.json'
    )
      .then((res) => {
        // To test pagination locally:
        // setRows(res.offerings.concat(compost(res.offerings, 17)))
        // TODO: Update models to have `offerings` @required
        setRows(res.offeringReviews);
      })
      .catch((e) => {
        console.error(e);
        return [];
      });
  }, [queriedMocks, token]);
  useEffect(() => {
    console.log('rows', rows);
    setFilteredRows(
      rows.filter(({ offeringName, ripServiceId, offeringReviewId }) =>
        [offeringName, ripServiceId, offeringReviewId].some((str) =>
          str?.toLocaleLowerCase().includes(filteringText.toLocaleLowerCase())
        )
      )
    );
    setCurrentPage(1);
  }, [rows, filteringText]);
  const countPages = () =>
    Math.ceil(filteredRows.length / (preferences.pageSize ?? 20));
  const [totalPages, setTotalPages] = useState(countPages());
  useEffect(() => {
    // setItems(rows);
    setItems(getPage(currentPage, preferences.pageSize ?? 20));
  }, [preferences.pageSize, currentPage, orderedRows, totalPages]);
  useEffect(() => {
    setTotalPages(countPages());
  }, [preferences.pageSize, filteredRows]);
  useEffect(
    () => setOrderedRows(orderRows()),
    [filteredRows, sortingDescending, sortColumn]
  );
  return (
    <>
      <Table
        stickyColumns={{ first: 1, last: 0 }}
        variant='container'
        contentDensity='comfortable'
        filter={
          <TextFilter
            filteringPlaceholder='Find an offering'
            filteringText={filteringText}
            onChange={(e) => setFilteringText(e.detail.filteringText)}
          />
        }
        sortingColumn={sortColumn}
        onSortingChange={(e) => {
          setSortColumn(e.detail.sortingColumn);
          setSortingDescending(e.detail.isDescending ?? true);
        }}
        visibleColumns={preferences.visibleContent}
        header={
          <Header
            counter={`(${filteredRows.length})`}
            actions={
              user?.permissions.WRITE?.includes('OFFERING_REVIEW') && (
                <Button variant='primary' href={`#/Moniker/create`}>
                  Create offering
                </Button>
              )
            }
          >
            Offering drafts
          </Header>
        }
        sortingDescending={sortingDescending}
        columnDefinitions={[
          // {
          //   id: 'controls',
          //   header: '',
          //   isRowHeader: false,
          //   cell: (serv) => (
          //     <Center>
          //       <Button
          //         onClick={() => {
          //           document.location.hash = `/Moniker/drafts/${serv.offeringReviewId}`;
          //         }}
          //         variant={'icon'}
          //         iconName='edit'
          //       />
          //     </Center>
          //   ),
          // },
          ...offeringReviewPropList.map(
            (prop): TableProps.ColumnDefinition<OfferingReview> => ({
              id: prop,
              header: fieldTitles[prop],
              sortingField: prop,
              isRowHeader: prop === 'offeringName',
              cell: (serv: OfferingReview) =>
                makeOfferingOrDraftCell(serv, prop),
            })
          ),
        ]}
        items={items}
        preferences={
          <OfferingListCollectionPreferences
            fields={offeringReviewPropList}
            preferences={preferences}
            setPreferences={setPreferences}
          />
        }
        pagination={
          <Pagination
            onPreviousPageClick={() => setCurrentPage(currentPage - 1)}
            currentPageIndex={currentPage}
            onNextPageClick={() => setCurrentPage(currentPage + 1)}
            pagesCount={totalPages}
            onChange={(e) => setCurrentPage(e.detail.currentPageIndex)}
          />
        }
      />
    </>
  );
};
